<template>
  <div class="wrap">
    <img src="../../assets/img/modify.png" alt="logo图片" width="100%">
    <van-cell title="变更还款日" is-link :to="{path:'modifyRepayment'}" />
    <van-cell title="手机号变更" is-link :to="{path:'modifyInfo'}" />
    <van-cell title="扣款卡变更" is-link :to="{path:'modifyDebitCard'}" />
  </div>
</template>
<script>
import {getOpenId } from '@/server'
import { Toast} from 'vant';
import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
  data() {
    return {
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
    };
  },
  mounted(){
    sessionStorage.setItem('key', 1);
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
      // if(sessionStorage.getItem('key') != 1) {
      //   sessionStorage.setItem('key', 1);
      // }
      // window.history.pushState('forward', null, '#');
      if(sessionStorage.getItem('key') == 1) {
        document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false); 
        WeixinJSBridge.call('closeWindow');
        // parent.WeixinJSBridge.call('closeWindow');
      }
    }, false);
  },
  created(){
    // this.$router.push({ path: '/login?redirect=/afterRentModify' });
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/afterRentModify' });
          }else{
            this.getDetail();
          }
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/afterRentModify');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/afterRentModify' });
            }else{
              this.getDetail();
            } 
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    getDetail(){
      this.$store.commit('imgUrlList', []);
      this.$store.commit('backFlag', '');
    }
  },
};
</script>
<style scoped>
.wrap{ 
    background-color: #fff; 
  }
</style>
<style>
/* #app{
  background: #fff !important;
} */
</style>